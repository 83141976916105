.container {
  margin-right: 12px;
  height: 14px;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: #049a49;
}

.icon {
  position: relative;
  top: 2px;
  display: inline-block;
}

.text-block {
  margin-left: 3px;
  display: inline-block;
}
