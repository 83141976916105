.link {
  padding: 0;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  text-decoration: none;
  color: var(--accent-main-primary);
}

.offers-count {
  margin-left: 4px;
  font-size: 12px;
  color: #7a7a7a;
}
