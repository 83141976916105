.details {
  display: flex;
}

.details-item:not(:first-child) {
  margin-left: 32px;
}

.details-item__label {
  margin-bottom: 4px;
}
