.container {
  position: fixed;
  z-index: 10;
  top: 45%;
  left: calc(50% - 90px);
  padding: 12px 20px;
  min-width: 182px;
  background-color: var(--overlay-media-banner);
  border-radius: 8px;
}
