.container {
  margin: 0;
  padding: 0 16px;
  display: flex;
  overflow: scroll hidden;
  list-style: none;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 16px;
  scrollbar-width: none;
  pointer-events: auto;
}

.container li {
  flex: 0 0 auto;
  scroll-snap-align: start;
  max-width: 100%;
}

.container li:nth-child(n + 2) {
  margin-left: 8px;
}

.container::after {
  padding: 8px;
  content: '';
}
