.offer-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.offer-header.with-discount {
  gap: 8px;
}

.price {
  display: inline-block;
  white-space: nowrap;
}

.highlighted {
  background-color: #fff500;
  border-radius: 2px;
}

.additional-price-info {
  display: inline-flex;
  align-items: center;
  align-self: center;
}
