.stats {
  margin-top: 10px;
  border-top: 1px solid var(--stroke-border-default);
}

.button {
  padding: 16px 0 6px;
  display: flex;
  place-content: center space-between;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  color: #2b87db;
  background-color: transparent;
  border: 0;
  outline: none;
}
