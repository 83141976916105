.actual {
  padding: 12px;
  display: flex;
  background: #f1f2f4;
  align-items: center;
  border: 1px solid var(--gray10_100);
}

.label {
  margin-right: 8px;
}
