.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
}

.description {
  margin-bottom: 12px;
  display: flex;
  font-size: 16px;
}

.description .text {
  margin-left: 9px;
}

.read-more {
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  color: #2b87db;
  background: transparent;
  border: none;
}

.content {
  flex-shrink: 1;
  overflow: auto;
}

.prof-block-link {
  margin-top: 8px;
  display: inline-block;
  font-size: 16px;
  text-decoration: none;
  color: var(--accent-main-primary);
}
