.container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
}

.paper {
  background-color: white;
  border: none;
}

.paper + .paper {
  margin-top: 10px;
}

@media (min-width: 642px) {
  .paper {
    border: 1px solid #e4e4e4;
  }
}
