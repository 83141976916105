.container {
  padding: 15px;
}

.footer {
  margin-top: 16px;
}

.actions {
  margin: 16px -16px 0;
  padding: 16px 16px 0;
  border-top: 1px solid #e4e4e4;
}
