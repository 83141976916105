.container {
  position: relative;
  margin-bottom: 8px;
  width: 100%;
  white-space: nowrap;
}

.left {
  padding-right: 32px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  & > * {
    margin-right: 8px;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.right {
  position: absolute;
  top: -10px;
  right: -10px;
}
