.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  color: white;
  background-repeat: no-repeat;
  background-position: 50%;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-color: white;
  background-size: contain;
}

.container > div {
  z-index: 1;
}

.container svg {
  width: 40px;
  height: 40px;
}

.container::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(18, 18, 18, 0.4);
}

.video-container {
  height: 100%;
}
