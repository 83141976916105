.container {
  position: absolute;
  z-index: 10;
  left: calc(50% - 62px);
  bottom: 16px;
  padding: 8px 10px;
  display: flex;
  background: var(--overlay-default);
  border: none;
  border-radius: 4px;
  align-items: center;
  outline: none;
}

.container > svg {
  margin-right: 8px;
}
