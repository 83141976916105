.container {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid hsla(0, 0%, 79%, 0.4);
}

.title {
  margin-top: 6px;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  transition: color 0.15s;

  &:hover {
    color: #1a62a6;
  }
}

.description {
  margin: 0;
  padding: 0 12% 6px 0;
  display: block;
  line-height: 22px;
}
