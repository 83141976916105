.container {
  position: relative;
  margin: 16px;
  padding: 20px 0 20px 16px;
  overflow: hidden;
  height: 150px;
  border-radius: var(--unit_4);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  max-width: 174px;
  height: 100%;

  & > a {
    margin-top: auto;
  }
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  width: 134px;
  height: 100%;
  object-fit: contain;
}
