.button {
  margin: 0;
  margin-left: 4px;
  padding: 0;
  width: 16px;
  height: 16px;
  background: transparent;
  border: none;
  outline: none;
}
