.container {
  margin: 12px 0 0;
  padding: 0;
}

.clause {
  position: relative;
  margin-bottom: 16px;
  margin-left: 20px;
  list-style-type: none;
  font-size: 16px;
  line-height: 20px;
}

.clause::before {
  position: absolute;
  left: -20px;
  font-size: 16px;
  line-height: 20px;
  content: '\2022';
  color: rgba(18, 18, 18, 0.5);
}
