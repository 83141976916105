.agent-avatar {
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #b7b7b7;
  background-color: var(--overlay-control-dark);
  border: 1px solid #e4e4e4;
  border-radius: 3px;
}
