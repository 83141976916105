.agent-block {
  padding: 15px;
  display: flex;
  min-height: 42px;
  text-decoration: none;
  color: var(--decorative-theme-dark);
}

.agent-info {
  margin-left: 8px;
  align-self: center;
  flex-grow: 1;
}

.badges {
  margin-left: 15px;
}
