.container {
  margin-left: 5px;
}

.increase {
  color: var(--accent-negative-primary);
}

.decrease {
  color: var(--accent-positive-primary);
}

.decrease svg {
  display: inline-block;
  transform: rotate(180deg);
}
