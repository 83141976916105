.amenities-container {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.amenities-container li {
  margin-bottom: 14px;
  list-style: none;
}

.amenities-container li:last-child {
  margin-bottom: 0;
}

.header {
  margin: 0 0 15px;
  font-size: 16px;
  font-weight: 600;
}
