@import '@cian/ui-kit/colors/variables.css';

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: var(--decorative-theme-white);
}

.container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
