.container {
  margin-bottom: 8px;
}

a.name {
  text-decoration: none;
}

.verified-by-cian {
  margin-right: 6px;
}

.similar-offers-count {
  margin-bottom: 2px;
  color: #ff9d00;
}
