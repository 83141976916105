.root.root {
  padding: 0;
  overflow: auto;
}

.container {
  margin-bottom: 10px;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-wrap: nowrap;
}
