.button {
  padding: 10px; /* При изменении нужно менять margin .loader */
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}

.loader {
  margin: 10px;
}
