.container {
  display: flex;
  flex-wrap: nowrap;
}

.action-right,
.action-left,
.action-center {
  display: flex;
  cursor: pointer;
}

.action-center {
  margin: 0 12px;
}
