.container {
  margin: 10px auto;
  padding: 15px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  color: #121212;
  background-color: #c9c9c9;
}
