.container {
  z-index: 1;
  margin-bottom: 8px;
  padding: 12px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.container:empty {
  display: none;
}
