.container {
  display: grid;
  grid-auto-flow: row;
  row-gap: var(--unit_1);
}

.factoid {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: var(--unit_1);
  align-items: center;
}
