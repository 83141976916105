.container {
  position: relative;
  margin-bottom: -12px;
  padding-top: 2px;
  overflow: hidden;

  & > *:last-child {
    margin-right: 0;
  }

  & > *:not(.arrow) {
    margin-right: 16px;
    margin-bottom: 12px;
  }
}

.collapsed {
  height: 72px;
}

@media only screen and (max-device-width: 389px) and (orientation: portrait) {
  .collapsed {
    height: 92px;
  }
}

.arrow {
  position: absolute;
  top: 0;
  right: 0;
}
