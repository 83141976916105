.button {
  position: relative;
  padding: 0;
  display: inline-block;
  width: 38px;
  height: 30px;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}

.main {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  width: 30px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: var(--decorative-theme-white);
  border-radius: 4px;
}

.trend {
  position: absolute;
  top: -2px;
  right: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  background: var(--decorative-theme-white);
  border: 2px solid #f1f2f4;
  border-radius: 50%;
}

.trend svg {
  width: 8px;
  height: 8px;
}
