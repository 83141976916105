.link {
  margin-top: 4px;
  padding: 0 14px;
  display: block;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-decoration: none;
  color: #4785d4;
}
