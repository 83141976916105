.icons {
  margin-left: 8px;
  display: inline-flex;

  & > * {
    margin-right: 4px;
  }

  & > *:last-child {
    margin-right: 0;
  }
}
