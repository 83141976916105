.round-button {
  position: absolute;
  left: 50%;
  bottom: 10px;
  margin-left: -22px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: rgba(18, 18, 18, 0.3);
  border: none;
  border-radius: 50%;
  outline: none;
}

.round-button:active {
  transform: scale(0.98);
}
