.item {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.item + .item {
  border-left: 1px solid #ced1d7;
}
