.container {
  margin: var(--unit_4);
  padding: var(--unit_4);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--Stroke-Border-Default, #c9d1e5);
  border-radius: var(--unit_3);

  & > :nth-child(1) {
    margin-bottom: var(--unit_1);
  }

  & > :nth-child(2) {
    margin-bottom: var(--unit_2);
  }

  & > :nth-child(3) {
    margin-bottom: var(--unit_1);
  }
}

.image {
  object-fit: contain;
}
