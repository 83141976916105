.container {
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  background: var(--decorative-theme-white);
}

.title {
  flex-shrink: 0;
  padding: 16px 12px 0;
  text-align: center;
}

.layout {
  flex-grow: 1;
}

.layout img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
