.container {
  font-size: 16px;
  line-height: 18px;
}

.under-construction {
  color: #7a7a7a;
}

.icon {
  position: relative;
  top: 2px;
  margin: 0 6px;
  display: inline-block;
}

.icon:first-child {
  margin-left: 0;
}
