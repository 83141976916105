.container {
  display: inline-block;
  width: 100%;
}

.buttons {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
}

.buttons > *:only-child {
  grid-column: span 2;
}
