.container {
  position: sticky;
  z-index: 5;
  bottom: 20px;
  margin: 20px auto;
  padding: 0 5px;
  display: flex;
  width: 100%;
  max-width: 640px;
}

.container > *:not(:first-child) {
  margin-left: 8px;
}
