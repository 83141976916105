.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  align-items: center;
  justify-content: center;
}

.text {
  padding-top: 8px;
  width: 178px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  color: black;
}
