.button {
  padding: 10px; /* При изменении нужно менять margin .loader */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: var(--accent-negative-primary);
  background: white;
  border: none;
  border-radius: 40px;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}

.loader {
  margin: 10px;
}
