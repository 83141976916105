.container {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #121212;
}

.item-wrapper {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
}

.item-wrapper:last-child {
  margin-bottom: 0;
}

.item-wrapper div {
  flex-basis: 50%;
}

.label {
  padding-right: 12px;
  color: #7a7a7a;
}

.value {
  text-align: right;
}
