.container {
  padding: 8px 12px;
  background-color: white;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  border: 1px solid #ced1d7;
  border-radius: 4px;
}

@media (min-width: 642px) {
  .container {
    border: 1px solid #e4e4e4;
    border-bottom: none;
  }
}
