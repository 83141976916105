.warning {
  padding: 12px;
  display: flex;
  background: var(--surface-warning-default);
  align-items: center;
}

.warn {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.label {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.description {
  margin-bottom: 24px;
}
