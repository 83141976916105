.paper-sections {
  padding: 32px 0;
  background: var(--decorative-theme-white);
}

.section {
  margin-bottom: 24px;
}

.paper-sections .section {
  margin-bottom: 32px;
}

.paper-sections > .section:last-child {
  margin-bottom: 0;
}

.padded-content {
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  max-width: 632px;
}

.unpadding {
  margin-right: -16px;
  margin-left: -16px;
}
