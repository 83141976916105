.pessimization {
  padding: 15px;
  font-size: 16px;
  line-height: 20px;
  color: white;
  background: #7a7a7a;
}

.head {
  margin-bottom: 5px;
  display: flex;
}

.title {
  flex: 1 1;
}

.icon-wrapper {
  padding-bottom: 4px;
  display: inline-flex;
  width: 38px;
  height: 38px;
  line-height: 38px;
  flex: 0 0 auto;
  background: #e4e4e4;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.collapsed-block-button {
  color: #c9c9c9;
}

.description {
  padding-top: 15px;
}

.pessimization.strong {
  background: #ed6d6d;
}

.strong .collapsed-block-button {
  color: white;
}

.warning_info_link {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  color: white;
}
