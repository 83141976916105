.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}

.content-left {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.content-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
