.eye-icon-container {
  display: inline-block;
  width: 14px;
  height: 10px;
}

.views {
  margin-left: 4px;
  font-size: 14px;
  color: #7a7a7a;
}
