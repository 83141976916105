.container {
  font-size: 16px;
  line-height: 18px;
}

.icon {
  position: relative;
  top: 2px;
  margin-right: 6px;
  display: inline-block;
}
