.offerinfo-item {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #7a7a7a;
}

.separated-list-item {
  display: inline-block;
}

.features {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #121212;
}

.features .separator {
  margin: 0 7px;
  font-size: 14px;
  color: #7a7a7a;
}

.title {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
  color: #121212;
}

.building-info {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.2;
  color: #7a7a7a;
}

.building-info .separator {
  margin: 0 5px;
}

.stead-status {
  margin-bottom: 4px;
  color: #121212;
}
