.container {
  position: relative;
  padding: 16px 0;
  content-visibility: auto;
  contain-intrinsic-size: 550px;
}

@media (min-width: 641px) {
  .container {
    padding: 14px 0;
    contain-intrinsic-size: 336px;
  }
}
