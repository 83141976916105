/* stylelint-disable */
:global {
  .slick-loading .slick-list {
    /* background: white url('./ajax-loader.gif') center center no-repeat; */
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    padding: 0;
    display: block;
    width: 20px;
    height: 20px;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    color: transparent;
    background: transparent;
    border: none;
    outline: none;
    transform: translate(0, -50%);
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    background: transparent;
    outline: none;
  }

  .slick-prev:hover::before,
  .slick-prev:focus::before,
  .slick-next:hover::before,
  .slick-next:focus::before {
    opacity: 1;
  }

  .slick-prev.slick-disabled::before,
  .slick-next.slick-disabled::before {
    opacity: 0.25;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: -25px;
  }

  [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
  }

  .slick-prev::before {
    content: '←';
  }

  [dir='rtl'] .slick-prev::before {
    content: '→';
  }

  .slick-next {
    right: -25px;
  }

  [dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
  }

  .slick-next::before {
    content: '→';
  }

  [dir='rtl'] .slick-next::before {
    content: '←';
  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    list-style: none;
    text-align: center;
  }

  .slick-dots li {
    position: relative;
    margin: 0 5px;
    padding: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .slick-dots li button {
    padding: 5px;
    display: block;
    width: 20px;
    height: 20px;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    color: transparent;
    background: transparent;
    border: 0;
    outline: none;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }

  .slick-dots li button:hover::before,
  .slick-dots li button:focus::before {
    opacity: 1;
  }

  .slick-dots li button::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    content: '•';
    color: black;
    opacity: 0.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots li.slick-active button::before {
    color: black;
    opacity: 0.75;
  }
}
