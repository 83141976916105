.content {
  position: relative;
  color: #121212;
}

.gallery {
  overflow: hidden;
  height: 188px;
  border-radius: 4px;
  isolation: isolate;
}

.vas-title {
  margin-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
}

.photo-label {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 15px;
}

.content {
  position: relative;
}

.phone-buttons {
  margin-top: var(--unit_3);
}

.online-status:not(:empty) {
  margin: var(--unit_2) 0 var(--unit_3);
}

.show-phone {
  position: relative;
  z-index: 8;
  margin-right: 15px;
  display: inline-block;
}

.media {
  position: relative;
  margin-bottom: 12px;
  display: block;
}

.favorite-button {
  position: absolute;
  z-index: 2;
  top: 12px;
  right: 12px;
  display: inline-flex;
}

@media (min-width: 641px) {
  .card {
    display: flex;
    flex-flow: row wrap;
    min-height: 336px;
  }

  .gallery {
    height: 100%;
  }

  .gallery_promoted:not(:last-child) {
    height: calc(100% - 70px);
  }

  .media {
    margin-bottom: 0;
    flex: 0 0 250px;
    max-width: 250px;
    max-height: 600px;
    order: 2;
  }

  .content {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    flex: 1 1 200px;
    order: 3;
  }

  .controls {
    position: relative;
    margin-top: auto;
    padding-top: 8px;
  }
}
