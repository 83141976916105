.root {
  margin: 0 auto 12px;
  padding: 0 16px;
  width: 100%;
  max-width: 632px;
}

.content {
  padding: 8px 12px;
  display: flex;
  width: 100%;
  background-color: white;
  border: 1px solid var(--stroke-border-default);
  border-radius: 4px;
  align-items: center;
}

.text-container {
  margin: 0 12px;
  overflow: hidden;
  width: 100%;
  flex-grow: 1;
}

.text-container span {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--decorative-theme-dark);
}

.text-container_placeholder span {
  color: var(--stroke-control-hovered);
}
