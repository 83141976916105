.container {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 355px;
  border-radius: 8px;
}

.container::after {
  padding-bottom: 97.23%;
  display: block;
  content: '';
}

.content {
  position: absolute;
  inset: 0;
  content-visibility: auto;
}
