.root {
  display: flex;
  align-items: center;
}

.root:not(.no-gap) > *:nth-child(2) {
  margin-left: var(--unit_4);
}

.root.no-gap > *:nth-child(2) {
  margin-left: var(--unit_1);
}

.root.no-gap *::before {
  content: none;
}

.reviews {
  color: #707a95;
}
