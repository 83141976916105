.button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}

.text {
  overflow: hidden;
  max-width: 116px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--decorative-theme-dark);
}
