.page {
  padding-top: 48px;
  display: flex;
  justify-content: center;
  min-height: 100%;
  background: white;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
}

.heading {
  margin-bottom: 8px;
}

.warning {
  margin-top: 16px;
}
