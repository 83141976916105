.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.blur-bg {
  position: absolute;
  z-index: 1;
  inset: 0;
  filter: blur(10px) brightness(0.9);
  background-position: 50%;
  background-size: cover;
  content-visibility: auto;
}

.image {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
