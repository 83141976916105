.scrollable {
  position: relative;
  margin: 0 -16px;
  padding: 0 16px;
  display: flex;
  overflow: scroll hidden;
  list-style: none;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 16px;
  scrollbar-width: none;
  pointer-events: auto;
}

.scrollable::after {
  padding: 8px;
  content: '';
}
