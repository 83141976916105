.container {
  position: fixed;
  z-index: 9;
  right: 0;
  left: 0;
  bottom: 12px;
  margin: 0 auto;
  padding: 6px 24px;
  width: 242px;
  background-color: var(--decorative-theme-dark);
  border-radius: 18px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s;
}

.with_tapbar {
  bottom: calc(var(--unit_3) + 60px);
}

.content {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.logo {
  margin-right: 12px;
  width: 20px;
  min-height: 20px;
  background: url('./assets/logo.svg') no-repeat;
  background-position: center center;
  background-size: contain;
}
