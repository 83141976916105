.domrf_promo_container {
  display: flex;
  height: 180px;
  text-decoration: none;
  color: white;
  flex-flow: column nowrap;
  place-content: center center;
  align-items: center;
  background-image: linear-gradient(rgba(21, 34, 66, 0.6), rgba(21, 34, 66, 0.6)), url('./assets/domrf_bg.jpg');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.domrf_logo {
  width: 60px;
  height: 60px;
  background-image: url('./assets/domrf_logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.domrf_title {
  margin: 10px auto 8px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: white;
}
.domrf_link {
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 425px) {
  .domrf_title {
    max-width: 225px;
  }
}
