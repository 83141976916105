@import '@cian/ui-kit/typography/variables.module.css';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  user-select: none;
  color: #7a7a7a;
  background-color: var(--gray6_100);
}

.container--pessimized {
  background-color: var(--gray40_100);
}

.placeholder svg {
  width: 40px;
  height: 40px;
}
