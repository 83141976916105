.container {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 4px;
  display: inline-flex;
}

.station-name {
  margin-right: 12px;
}
