.compensation {
  margin-bottom: -20px;
}

.container {
  position: relative;
  z-index: 0;
  padding: 12px 20px;
}

.background {
  position: absolute;
  /* Фикс мыла на содержимом https://stackoverflow.com/questions/66636625/how-to-disable-backdrop-filter-blur-from-content-inside-a-div */
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(2px);
}

.sticky {
  z-index: 5;
}
