.label {
  padding: 0 8px;
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  height: 20px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  background-color: #ff5050;
  border-radius: 3px;
}
