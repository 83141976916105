.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(21, 34, 66, 0.4);
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
}

.banner--red {
  background: rgba(255, 31, 52, 0.4);
}

.banner > p {
  margin-top: 8px;
}
