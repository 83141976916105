@import '@cian/ui-kit/typography/variables.module.css';

.button {
  position: fixed;
  z-index: 2;
  left: var(--unit_3);
  bottom: calc(var(--unit_3) + 34px + var(--unit_3));
  padding: var(--unit_3) var(--unit_5);
  display: flex;
  align-items: center;
  gap: var(--unit_2);
  text-decoration: none;
  background: rgba(255, 255, 255, 0.6);
  border-radius: var(--unit_1);
  box-shadow: 0 var(--unit_2) var(--unit_4) 0 rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(10px);
}

.with_tapbar {
  bottom: calc(60px + var(--unit_3) + 34px + var(--unit_3));
}
