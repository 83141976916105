.header {
  position: relative;
  padding: 13px 15px;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  cursor: pointer;
  color: var(--decorative-theme-dark);
  align-items: center;
  justify-content: space-between;
}

.highlighted {
  color: var(--accent-main-primary);
}
.title {
  margin-bottom: -15px;
}
