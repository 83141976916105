.card {
  padding: 16px;
  display: flex;
  background-color: rgba(255, 31, 52, 0.1);
  border-radius: 8px;
  align-items: flex-start;
}

.title {
  margin-bottom: 4px;
}

.icon {
  margin-top: 2px;
  padding-right: 16px;
}
