.content {
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s linear;
  transform: translateZ(0);
}

.content::after {
  opacity: 0;
}

.with-shadow::after {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 15px;
  content: '';
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 90%);
  opacity: 1;
  transition: opacity 0.3s linear;
}

.with-button .content-wrapper {
  margin-bottom: 8px;
}
