.container {
  margin-bottom: 8px;
  display: block;
  text-decoration: none;
}

.container > span {
  display: inline;
}

.separator {
  margin: 0 7px;
  font-weight: normal;
  color: #7a7a7a;
}
