.thumbnails {
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 8px;
  height: 60px;
}

.thumbnails img {
  width: 100%;
  height: 100%;
  min-height: 0;
  border-radius: 4px;
  object-fit: cover;
  content-visibility: auto;
}
