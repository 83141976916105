.container {
  margin: var(--unit_4);
  padding: var(--unit_4);
  display: flex;
  gap: var(--unit_3);
  align-items: flex-start;
  background: var(--Solid-Gray50, #f3f6ff);
  border-radius: var(--unit_3);
}

.image {
  object-fit: contain;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & a[class*='button'] {
    padding-left: 0;
    background: none;
  }

  & > :nth-child(1) {
    margin-bottom: var(--unit_2);
  }

  & > :nth-child(2) {
    margin-bottom: var(--unit_1);
  }
}
