.container {
  display: flex;
  align-items: flex-start;
}

.icon {
  margin-right: 12px;
  flex: none;
  transform: translateY(2px);
}

.info {
  flex-grow: 1;
}

.title {
  margin-bottom: 4px;
}
