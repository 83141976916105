.layout {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  height: 240px;
  min-height: 100%;
  text-align: center;
  cursor: pointer;
  background-size: cover;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.layout::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: rgba(0, 0, 0, 0.4);
}

.text {
  position: relative;
  margin-bottom: 16px;
  max-width: 526px;
}

.text::before {
  margin-bottom: 12px;
  display: inline-block;
  width: 64px;
  height: 64px;
  content: '';
  background: url('./assets/icon.svg') no-repeat;
  background-size: cover;
}
