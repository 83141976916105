.icons {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.icons > * {
  margin-left: 8px;
}

.icons > *:first-child {
  margin-left: 0;
}
