.description {
  display: none;
}

@media (min-width: 641px) {
  .description {
    margin-top: 8px;
    display: block;
    /* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
    display: -webkit-box;
    overflow: hidden;
    /* Fallback for no-webkit */
    max-height: 40px;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* stylelint-enable */
  }
}
