.container {
  padding: var(--unit_2) 0 var(--unit_4);
}

.banner {
  padding: var(--unit_4);
  display: flex;
  align-items: center;
  text-decoration: none;
  background-image: url('./assets/image.svg'), linear-gradient(to bottom, rgba(0, 108, 253), rgba(0, 108, 253, 0.5));
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
  border-radius: var(--unit_2);
}
