.pro {
  display: inline-block;
  width: 36px;
  height: 16px;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  background-color: rgba(18, 18, 18, 0.1);
  border-radius: 3px;
}
