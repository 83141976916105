.container {
  margin-bottom: 12px;
  display: flex;
  overflow: scroll hidden;
  list-style: none;
  text-decoration: none;
  pointer-events: auto;
  scroll-padding-left: 14px;
  column-gap: 8px;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.container::after {
  padding-right: 14px;
  content: '';
}

@media (min-width: 641px) {
  .container {
    margin-bottom: 8px;
  }
}
