.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.reviews {
  color: var(--decorative-theme-dark);
}
