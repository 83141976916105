.card {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 16px;
  padding: 16px;
  display: flex;
  max-width: 355px;
  background-color: rgba(21, 34, 66, 0.4);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.wrap {
  position: relative;
}

.text {
  padding-left: 10px;
  font-size: 14px;
  color: white;
}

.icon {
  margin-top: 4px;
}
