.container {
  width: 100%;
  height: 100%;
  background: white;
}

.container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
