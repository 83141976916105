.btn {
  padding: 12px 16px;
  display: flex;
  width: 100%;
  text-decoration: none;
  background-color: var(--decorative-theme-white);
  border: none;
  border-radius: 4px;
  align-items: center;
}

.icon {
  margin-right: 12px;
  display: flex;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.arrow {
  margin-left: auto;
}

.texts:nth-child(2) {
  color: var(--text-secondary-default);
}
