.link {
  padding: 14px 12px;
  display: block;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  color: var(--accent-main-primary);
}
