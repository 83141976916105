.button {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: var(--accent-main-primary);
  background: none;
  border: none;
  align-items: center;

  &:disabled {
    color: var(--gray20_100);
  }

  & > * {
    margin-left: 8px;
    /* Иконка по умолчанию имеет анимацию цвета, но здесь это не нужно: помимо цвета меняется и текст - с анимацией выглядит плохо */
    transition: none !important; /* stylelint-disable-line declaration-no-important */
  }
}
