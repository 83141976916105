.container {
  margin-bottom: 12px;
}

.price-wrapper {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}

.price {
  flex: 1;
  overflow: hidden;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #121212;
}

.price-history {
  flex: 1;
  align-self: flex-end;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: var(--accent-main-primary);
}

.additional-price {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #7a7a7a;
}

.auction-subtitle {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}
