.compensation {
  margin-bottom: -16px;
}

.container {
  position: relative;
  z-index: 0;
  padding: 8px 16px 16px;
}

.background {
  position: absolute;
  /* Фикс мыла на содержимом https://stackoverflow.com/questions/66636625/how-to-disable-backdrop-filter-blur-from-content-inside-a-div */
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(8px);
}

.sticky {
  z-index: 5;
}
