.container {
  all: unset;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  width: 100%;
}

.avatar {
  flex-shrink: 0;
}

.content {
  margin-left: 12px;
  overflow: hidden;
  width: 100%;
}

.content > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
