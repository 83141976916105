.discount {
  margin-bottom: var(--unit_1);
}

.price-row {
  margin-bottom: 6px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.price {
  flex: 0 1 auto;
  font-size: var(--fontSize_28px);
  font-weight: bold;
  line-height: var(--lineHeight_36px);
  color: var(--decorative-theme-dark);
}

.actions {
  margin-left: 8px;
  flex: 0 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  min-height: var(--lineHeight_36px);
}

.additional-price {
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  color: var(--gray60_100);
}

.additional-price + .additional-price {
  margin-top: 4px;
}

.price-relevance {
  margin-bottom: var(--unit_1);
  display: flex;
  gap: 10px;
}

.price-relevance span {
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_16px);
  color: var(--text-secondary-default);
}

.price-relevance-icon {
  display: flex;
  align-items: center;
}

.purchase-terms {
  margin-bottom: var(--unit_1);
}
