.empty-listing-banner {
  margin-top: var(--unit_7);
  margin-bottom: var(--unit_1);
  display: flex;
  height: 124px;
  align-items: center;
  column-gap: var(--unit_7);

  &--image {
    background-image: url('./assets/image.svg');
    background-repeat: no-repeat;
  }

  &--text {
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: var(--unit_2);
    justify-content: center;
  }
}
