.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  user-select: none;
  color: #7a7a7a;
  background-color: #e4e4e4;
}
