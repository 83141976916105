.container {
  display: block;
}

.title {
  margin-bottom: 8px;
}

.buttons-container {
  margin-top: 12px;
}

.button:not(:first-child) {
  margin-top: 4px;
}

.link-button {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
