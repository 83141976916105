.map {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 188px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  content-visibility: auto;
  contain-intrinsic-size: 188px;
}

.map svg {
  position: relative;
  top: -20px;
}

.icon {
  width: 50px;
  height: 50px;
  transform: translateY(calc(-50% + 5px));
}
