/* stylelint-disable */
:global {
  .slick-container,
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }
}
/* stylelint-enable */

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  position: absolute;
  right: 15px;
  bottom: 10px;
  display: flex;
}

.counts {
  padding: 0 8px;
  display: inline-block;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  user-select: none;
  pointer-events: none;
  color: white;
  background-color: rgba(24, 41, 48, 0.8);
  border-radius: 3px;
}
