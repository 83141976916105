.container {
  position: relative;
  padding: 0 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 330px;
  background: white;
}

.logo {
  margin-bottom: 16px;
  display: block;
  width: 250px;
  height: 40px;
  background: url('assets/mortgage-logo.svg');
}

.text {
  margin: 0;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  color: var(--decorative-theme-dark);
}

.banks-block {
  margin-bottom: 24px;
}
