.container {
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 280px;
  background-color: #e8e9ec;
  border-radius: 6px;
}

.content {
  display: block;
  text-align: center;
}

.content svg {
  width: 40px;
  height: 40px;
}
