.header {
  padding: 15px;
  font-family: Lato;
  font-size: 16px;
}

.jk-link {
  margin-bottom: 5px;
  display: block;
  font-weight: 600;
  line-height: 1.2;
  text-decoration: none;
  color: #2b87db;
}

.address {
  margin-bottom: 5px;
  font-weight: bold;
  line-height: 22px;
  color: #121212;
}
