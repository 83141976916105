.container {
  margin-bottom: var(--unit_1);
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  margin-right: var(--unit_1);
  display: inline-block;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
}
