.meta {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_18px);
  color: var(--gray40_100);
}

.meta-upper {
  font-size: var(--fontSize_14px);
}

.views {
  font-size: var(--fontSize_14px);
  color: var(--gray40_100);
}

.meta:not(:last-child) {
  margin-bottom: 8px;
}

.date {
  margin-right: 12px;
}
