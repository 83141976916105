.main-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.price {
  flex-shrink: 0;
}

.actions {
  margin-left: 16px;
  flex: 0 0 auto;
}

.actions:empty {
  display: none;
}

section.no-margin {
  margin-bottom: 0;
}

.price-total {
  margin: 4px 0;
}

.price-total span {
  color: var(--text-secondary-default);
}
