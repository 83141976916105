.container {
  position: relative;
}

.scrollable {
  margin: 0;
}

ul.scrollable li {
  display: flex;
  width: 100%;
  max-width: 355px;
}
