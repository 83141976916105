.root {
  overflow: hidden;
}

.fill {
  background: #dfe1e8;
  border-radius: 4px;
}

.animated {
  animation: fade 0.8s linear infinite alternate;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
