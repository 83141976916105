.container {
  margin: 8px 0 10px;
  padding: 16px 0 24px;
  background-color: white;
}

.title {
  margin: 0 10px 16px;
}

.button {
  margin-right: 10px;
  margin-left: 10px;
}

.list {
  margin: 25px 0 35px;
  padding: 0 10px;
  display: flex;
  overflow: scroll hidden;
  width: 100%;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 16px;
  scroll-padding-right: 16px;
}

.list::after {
  padding-right: 10px;
  display: block;
  content: '';
}

@media (min-width: 641px) {
  .container {
    margin-right: 60px;
    margin-left: 60px;
  }
}
