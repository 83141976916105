.button {
  padding: 16px;
  display: flex;
  height: 94px;
  text-decoration: none;
  color: var(--decorative-theme-dark);
  background-image: url('assets/bg.png');
  background-repeat: no-repeat;
  border: none;
  flex-flow: column;
  flex-shrink: 0;
  border-radius: 16px;
  scroll-snap-align: start;
}

.button + .button {
  margin-left: 8px;
}

.button:nth-child(1) {
  background-position-x: 0;
}

.button:nth-child(2) {
  background-position-x: -173px;
}

.button:nth-child(3) {
  background-position-x: -346px;
}

.button:nth-child(4) {
  background-position-x: -519px;
}

.button:nth-child(5) {
  background-position-x: -692px;
}

.text {
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  text-align: left;
  white-space: pre-line;
}

.icon svg {
  margin-bottom: 12px;
  display: flex;
}
