.video-slide {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.preview {
  height: 100%;
}

.preview--blur {
  transform: scale(1.5);
  inset: 0;
  filter: blur(80px) saturate(1.3);
}

.video-label {
  position: absolute;
  z-index: 1;
  left: var(--unit_3);
  bottom: var(--unit_4);
  padding: 6px;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
}
