.container {
  position: relative;
  margin: 0 16px;
}

@media (min-width: 641px) {
  .container {
    margin-right: 60px;
    margin-left: 60px;
  }
}
