.container {
  margin-bottom: 16px;
}

.main-photo {
  position: relative;
  height: 150px;
  background-color: var(--gray6_100);
  background-position: center;
  background-size: cover;
  border-radius: 4px;
}

.thumbs {
  margin: 4px -2px;
  display: flex;
  height: 56px;
}

.thumbs > * {
  margin: 0 2px;
  width: 30%;
  flex: auto;
}

.title {
  margin-top: 16px;
  overflow: hidden;
  max-height: 48px;
}

/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
.title-link {
  display: -webkit-box;
  overflow: hidden;
  text-decoration: inherit;
  text-overflow: ellipsis;
  color: inherit;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
/* stylelint-enable */

.info {
  margin-top: 4px;
}

.builder-logo {
  position: absolute;
  top: 8px;
  left: 8px;
  overflow: hidden;
  width: 64px;
  height: 64px;
  background-color: var(--decorative-theme-white);
  border-radius: 4px;
}

.phone-button {
  margin-top: 16px;
}
