.container {
  position: fixed;
  z-index: 11;
  top: 0;
  padding: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  background: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.content {
  display: flex;
  justify-content: space-between;
}

.content-left {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
}

.content-right {
  margin-left: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
