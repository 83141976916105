.labels-container {
  position: absolute;
  left: 15px;
  bottom: 10px;
  display: flex;
  user-select: none;
  pointer-events: none;
  flex-flow: column nowrap;
  align-items: flex-start;
}
