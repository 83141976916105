.row {
  margin: 0;
  padding: 0;
  display: flex;
  overflow: scroll hidden;
  list-style: none;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  pointer-events: auto;
}

.title {
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  text-align: center;
  flex: 0 0 auto;
  scroll-snap-align: start;
}

.item + .item {
  margin-left: 24px;
}
