.root {
  padding: 16px 16px 24px;
  background: linear-gradient(180deg, #006cfd 0%, #ffdcc8 100%);
  box-shadow: 0 -8px 16px #9ea7bc40;
}

.content {
  display: flex;
  color: var(--decorative-theme-white);
}

.text {
  margin-top: 16px;
}

.description {
  margin-top: 4px;
}

.close {
  display: flex;
  justify-content: flex-end;
}

.button {
  margin-top: 24px;
}

.discount-image-root {
  margin-left: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-end;
}

.discount-image {
  width: auto;
  max-width: 133px;
  height: auto;
  max-height: 112px;
}
