.seo-block {
  margin-bottom: 60px;
  margin-left: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content {
  display: inline-block;
  width: 100%;
}
