.container {
  padding: 10px 15px;
  font-size: 16px;
  text-align: center;
  border-top: 1px solid var(--stroke-border-default);
}

.rate {
  font-weight: bold;
  color: #ff9d00;
}

.reviews-stars {
  margin-left: 3px;
  display: inline-block;
  line-height: 1;
  vertical-align: text-bottom;
}

.reviews-star {
  margin-left: 4px;
  width: 13px;
}

.no-reviews {
  margin: 0 10px;
  color: #7a7a7a;
}

.reviews-divider {
  margin: 0 10px;
  color: #121212;
}

.reviews-count {
  text-decoration: none;
  color: #2b87db;
}
