.content-block {
  padding: 15px;
  border-bottom: 1px solid var(--stroke-border-default);
}

.content-block:last-child {
  border-bottom: none;
}

.content-block--renovation {
  font-size: 16px;
  background-color: #fff2e1;
}

.content-block--renovation > a {
  font-weight: bold;
  text-decoration: none;
  color: #2b87db;
}

.special-background {
  background: #fef3e3;
}
