.container {
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.button {
  margin: 0;
  margin-left: var(--unit_2);
  padding: 0;
  min-width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
}

.icon {
  max-width: 40px;
  max-height: 40px;
}
