.container {
  width: 100%;
}

.name {
  margin-bottom: 8px;
  font-size: 14px;
}

.phone {
  margin-bottom: 8px;
  font-size: 16px;
}

.note {
  margin: 0 0 8px;
  font-size: 12px;
  line-height: 1.2;
  color: #7a7a7a;
}
